



























import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import I18n from '@/i18n/index';
import { NewsStore } from '@/repository/kontent/stores/newsStore';
import { reactive } from '@vue/composition-api';

@Component({
  components: {},
})
export default class LocalNav extends Vue {
  @Prop() targetYear!: string;
  private i18n = I18n;
  private wholeData = reactive(new NewsStore());

  private async created() {
    await this.getNewsYear();
  }

  @Watch('$route')
  async onRouteChanged(to: any): Promise<void> {
    await this.getNewsYear();
  }

  private getNewsYear() {
    this.wholeData.initQuery();
    this.wholeData.setElementsParameter(['date', 'news_group']);
    this.wholeData.restore();
  }

  private get yearArr() {
    const yearWholeArr = this.wholeData.getYearArr().sort((a, b) => {
      return a < b ? 1 : -1;
    });
    switch (+this.targetYear) {
      case yearWholeArr[0]:
      case yearWholeArr[1]:
        return yearWholeArr.slice(0, 5);
      case yearWholeArr[yearWholeArr.length - 1]:
      case yearWholeArr[yearWholeArr.length - 2]:
        return yearWholeArr.slice(-5);
      default: {
        const index = yearWholeArr.findIndex((i) => i === +this.targetYear);
        return yearWholeArr.slice(index - 2, index + 3);
      }
    }
  }
}
