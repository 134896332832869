





















































import { Component, Vue, Watch } from 'vue-property-decorator';
import { NewsStore } from '@/repository/kontent/stores/newsStore';
import I18n from '@/i18n/index';
import { reactive } from '@vue/composition-api';
import LocalNav from './LocalNav.vue';
import { formatDate } from '@/repository/kontent/utilities/languageCodes';
import { LabelCssVal } from '@/_ns_modules/src/ts/labelCssUtil';

@Component({
  components: {
    LocalNav,
  },
})
export default class Article extends Vue {
  private i18n = I18n;
  private targetYear = this.$route.params.year ?? '';
  private targetArticle = this.$route.params.article;

  private async created() {
    await this.getNewsArticle();
  }

  @Watch('$route')
  async onRouteChanged(to: any): Promise<void> {
    await this.getNewsArticle();
  }

  private articleData = reactive(new NewsStore());

  private async getNewsArticle() {
    // FIXME 処理が複雑すぎるため、構造から見直す。
    if (this.$route.params.id) {
      const id = this.$route.params.id;
      this.articleData.initQuery();
      this.articleData.setFilter('system.id', id);
    } else {
      this.articleData.initQuery();
      this.articleData.setYear(this.targetYear);
      this.articleData.setFilter('elements.url', this.targetArticle);
    }

    await this.articleData.restore();
    if (!this.body && !this.html) {
      this.$router.push(`/${this.i18n.language}/`);
    }

    const titleVal = this.title + ' | ' + this.$i18n.tc('corporateName');
    document.title = titleVal;
    document.querySelector("meta[property='og:title']")?.setAttribute('content', titleVal);

    document.querySelector("meta[name='description']")?.setAttribute('content', this.summary);
    document.querySelector("meta[property='og:description']")?.setAttribute('content', this.summary);
  }

  private get news() {
    return this.articleData.list[0];
  }

  private get date() {
    return formatDate(this.news?.date.value, I18n.language);
  }

  private get title() {
    return this.news?.title.value;
  }

  private get summary() {
    return this.news?.summary.value;
  }

  private get body() {
    return this.news?.body.value;
  }

  private get html() {
    return this.news?.htmlbody.value;
  }

  private get targetYearString() {
    return typeof this.targetYear === 'number' ? String(this.targetYear) : this.targetYear;
  }

  private get newsGroupVal() {
    const newsGroupArr = this.news?.newsGroup.value;
    if (!newsGroupArr) return null;
    return newsGroupArr.length > 0 ? newsGroupArr[0] : null;
  }

  private get groupVal() {
    return this.newsGroupVal?.codename ?? null;
  }

  private get groupClass() {
    const labelVal = this.newsGroupVal?.codename ?? 'other';
    return LabelCssVal[labelVal];
  }
}
