import { render, staticRenderFns } from "./Article.vue?vue&type=template&id=168a017b&"
import script from "./Article.vue?vue&type=script&lang=ts&"
export * from "./Article.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/i18n/localization/newsTaxonomy.json?vue&type=custom&index=0&blockType=i18n&issuerPath=D%3A%5Ca%5C1%5Cs%5Csrc%5Cviews%5Cnews%5CArticle.vue"
if (typeof block0 === 'function') block0(component)
import block1 from "@/i18n/localization/pageList.json?vue&type=custom&index=1&blockType=i18n&issuerPath=D%3A%5Ca%5C1%5Cs%5Csrc%5Cviews%5Cnews%5CArticle.vue"
if (typeof block1 === 'function') block1(component)
import block2 from "./Article.vue?vue&type=custom&index=2&blockType=i18n"
if (typeof block2 === 'function') block2(component)

export default component.exports